import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { Suspense, lazy ,useEffect,useState} from 'react'
import { NotificationContainer } from "react-notifications";
import { getCookie, redirectToCommonLogin, startLoader, stopLoader, verifyUser } from "../utilities/chatbotMethods";
import { getPaymentDetails } from "../features/payment/paymentSlice";
import GlobalLoader from "../Component/loaders/GlobalLoader";
import WindowResizeNotify from "../Component/test-copmonents/WindowResizeNotify";
import MobilePreview from "../Component/mobile/MobilePreview";


const  RunScript = lazy(() => import("../pages/RunScript"));
const UAT= lazy(() => import("../pages/uat"));

// import GoogleAuth from "../pages/GoogleAuth";


const HomePage = lazy(() => import('../pages/Chatboat/home'));
const AnalyticsNew = lazy(() => import('../pages/Analytics/AnalyticsNew'));
const ChatBoatTemplate = lazy(() => import('../pages/ChatbotTemplates/Index'));
// const PreviewTemp = lazy(() => import('../pages/ChatbotTemplates/PreviewTemp'));
const Editor = lazy(() => import('../pages/Chatboat/Editor/Editor'));
const SideMenu = lazy(() => import('../Component/sidemenu'));
const Login = lazy(() => import('../pages/Chatboat/Login'));
const Builder = lazy(() => import('../pages/createNewBot/Builder'));
const VerifyIntegration = lazy(() => import('../../src/Component/integration/VerifyIntegration'));
const Home = lazy(() => import('../pages/Home'));
const Settings = lazy(() => import('../pages/Settings/Settings'));
// const GlobalLoader = lazy(() => import('../Component/loaders/GlobalLoader'));
const SubscribeNow = lazy(() => import('../pages/payment/SubscribeNow'));
const SubscribeSuccess = lazy(() => import('../pages/payment/SubscribeSuccess'));
const SubscribeFail = lazy(() => import('../pages/payment/SubscribeFail'));
const TryAiBot = lazy(() => import('../pages/aibots/TryAiBot'));
const SlackSetup = lazy(() => import('../../src/Component/integration/SlackSetup'));
const GoogleAuth = lazy(() => import('../../src/pages/GoogleAuth'));
const Appointment = lazy(() => import('../pages/Appointment/Appointment'));
const DraftPrev = lazy(() => import('../pages/draftPrev/draftPrev'));

const AppRoute = () => {


    
  const [showFullMenu,setShowFullMenu]=useState(true)

    const dispatch = useDispatch();
   

    useEffect(async () => {
      
      startLoader("route")
      if (window.location.pathname.includes("/login") || window.location.pathname.includes("/builder") || window.location.pathname.includes("/googleauth")||window.location.pathname.includes("/googlesuccess") ||window.location.pathname.includes("/demo") || window.location.pathname.includes("/appointment")|| window.location.pathname.includes("/uat")) {
        
      }
      
      else{



        if(window.location.href.includes('aiBot')){
          sessionStorage.setItem('toSignUp' , true)
          sessionStorage.setItem(
            "loginSource",
            window.location.pathname + window.location.search
          );
        }else if (window.location.href.includes('claimtrial')){
          sessionStorage.setItem(
            "loginSource",
            window.location.pathname + window.location.search
          );
        }
        else if(window.location.href.includes('success')){
          sessionStorage.setItem(
            "loginSource",
            window.location.pathname
          );
        }
        else {
          const temp = window.location.pathname
          const loginSource = window.location.pathname +  window.location.search;
          console.log(loginSource , ' login source inside else') 

          if(loginSource == '/' || loginSource == '/login'){
            sessionStorage.removeItem("loginSource")
          }
          // else{
          //   sessionStorage.setItem(
          //     "loginSource",
          //     loginSource
          //   );
          // }
         
        }
// CHECK IF LOCALSTORAGE TABLEALIAS EQUALS COOKIE ALIAS
if(localStorage.tablealias){
        var loginCookie = getCookie('APPYID')
        const UserData = await verifyUser(loginCookie)
        if(!UserData.status){
          stopLoader('login')
          localStorage.clear()
          redirectToCommonLogin()
          
        }else{
          const chatbotUserId = UserData.data.filter(item => item.product === "chatbot").map(item => item.user_id);
          if(chatbotUserId!=localStorage.tablealias){
            stopLoader('login')
            localStorage.clear()
            redirectToCommonLogin()
          }
        }
      }
      else{
        redirectToCommonLogin()
      }
    }
      dispatch(getPaymentDetails())

  },[])
  const minimizeMenu=(toggle)=>{
    setShowFullMenu(toggle)
  }
  return (

    
    <>

      <div className="w-full min-h-screen bg-bodyBG">
        <NotificationContainer />
        {/* <Popup /> */}
        <GlobalLoader/>
        <Suspense fallback={<GlobalLoader active={true}/>}>

        <BrowserRouter>
        
        <SideMenu minimizeMenu={minimizeMenu}/>

          <Routes>
         
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cx/Chatbot" element={<HomePage />} />
            <Route path="/cx/chatbot" element={<Home />} />
            <Route path="/cx/Chatbot/:type" element={<Editor />} />
            <Route path="/cx/Chatbot/:type/:botPage/:appType" element={<Editor />} />
            <Route path="/cx/Chatbot/:type/:botPage" element={<Editor />} />
            <Route path="/draft/:alias/:botId" element={<DraftPrev/>} />
            <Route path="/cx/analytics" element={<AnalyticsNew />} />
            <Route path="/cx/chatBoatTemplate" element={<ChatBoatTemplate />} />
            {/* <Route path="/cx/appointment/:page" element={<Appointment />} /> */}
            <Route path="/cx/settings/:page" element={<Settings showFullMenu={showFullMenu}/>} />            
            <Route path="/verify" element={<VerifyIntegration />} />
            <Route path="/builder" element={<Builder />} />
            <Route path="/subscribe" element={<SubscribeNow />} />
            <Route path="/claimtrial" element={<SubscribeNow />} />
            <Route path="/subscribe/success" element={<SubscribeSuccess />} />
            <Route path="/subscribe/fail" element={<SubscribeFail />} />
            <Route path="/trial/success" element={<SubscribeSuccess />} />
            <Route path="/trial/fail" element={<SubscribeFail />} />
            {/* <Route path="/previewTemp" element={<PreviewTemp />} /> */}
            <Route path = '/aiBot' element = {<TryAiBot/>}/>
            <Route path = '/switch' element = {<Builder/>}/>
            <Route path = '/slack' element = {<SlackSetup/>}/>
            <Route path = '/googleauth' element={<GoogleAuth/>}/>
            <Route path='/googlesuccess' element={<GoogleAuth/>}/>
            <Route path='/test' element={<WindowResizeNotify/>}/>
            <Route path="/mobile/:page" element={<MobilePreview />} />
            <Route path="/demo" element={<RunScript/>} />
            <Route path="/appointment" element={<Appointment/>} />
            <Route path="/uat" element={<UAT/>} />
            


          </Routes>
        </BrowserRouter>
        </Suspense>

      </div>
    </>
  );
};

export default AppRoute;
