import { set } from 'date-fns';
import { el } from 'date-fns/locale';
import React, { useState , useEffect } from 'react'
import { useLocation, useNavigate , useParams } from 'react-router-dom';
import { db } from "./../../../config/firebase";
import SocialAppsMobile from './SocialAppsMobile';
import { NotificationManager } from "react-notifications";

import Website from './Website';

function SetupMobile(props) {

    //destructuring props
    const {internalBotScript ,previewInternalBot ,  setupData , defaultSocialAppInMobile} = props



    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()


    //constants
    // const DEFAULT_TABS = ['Deploy bot in website' , 'Deploy bot on social media apps']

    //local states
    const [tabs , setTabs] = useState(null)
    const [selectedTab , setSelectedTab] = useState(null)
    const [allowedSetupData , setAllowedSetupData] = useState(null)
    const [onlySocialAppsData , setOnlySocialAppsData] = useState(null)
    const [isLoading , setIsLoading] = useState(true)
    




  



    //handler function
    const handleTabClick = (index) => {
        setSelectedTab(selectedTab === index ? null : index)
        if(index === 0){
            navigate(`/cx/Chatbot/${localStorage.getItem("bot_type")}/setup?did=${localStorage.getItem("botId")}`)
        }
        
    }

    //useEffectsetupData
    useEffect(() => {
        console.log(setupData , '   setupData')
        const deploymentOptions = setupData.deployment_options
        setIsLoading(true)

        //filtering the setup data
        //getting only the allowed options anf their data
        const allowedOptions =  deploymentOptions.filter(item => {
            return !item.botlist || (item.botlist && item.botlist.includes(parseInt(localStorage.getItem("botType")))) && item.id !== 'sdk'
        })
        console.log(allowedOptions , '   allowedOptions')

        //setting local states
        setAllowedSetupData(allowedOptions)
        setTabs(allowedOptions.map(item => item.desc))
        // setAllowedAppIds(allowedOptions.map(item => item.id))
        setOnlySocialAppsData(allowedOptions.filter(item => item.id === 'social_media_platform')[0])

        //if appType is present then opening the social app by default
        if(defaultSocialAppInMobile){
            setSelectedTab(1)
        }
        else{
            setSelectedTab(null)
        }
        setIsLoading(false)
        
        
    },[])



       //utility function for copy text
  //copy the given text to clipboard
 const copyToClipboard = (text) => {
  
    navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text copied to clipboard: ' + text);
      NotificationManager.success('', 'text copied', 2000)
      // You can provide user feedback here (e.g., display a success message)
    })
    .catch((err) => {
      console.error('Unable to copy text: ', err);
      NotificationManager.success('', 'something went wrong', 2000)
      // Handle errors (e.g., display an error message)
    });
   }
  


   

    
  return (
    <div className='traingpt-mobileview'>

        {/* <div className='flex justify-between py-3 px-3 items-center bg-white'>
            <button type='button' onClick={handleBackClick } className = "cursor-pointer p-2">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="23" width="23" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clip-rule="evenodd"></path></svg>
            </button>
            <h2 className='text-[#334763] text-lg font-medium mb-0 text-center mx-auto'>Widget Setup</h2>
        </div> */}


        {isLoading ? ""  :  <div className=' mr-5 ml-5 mt-5 rounded-lg'>
            {tabs.map((tab , index) => {
                return <div key={index} className='bg-[#fff] rounded-lg shadow-xl  p-5 mb-5 cursor-pointer' >
                    <div className=' flex justify-between' onClick={() => {handleTabClick(index)}}>
                        <h2 className='text-[#334763] text-base font-semibold'>{tab}</h2>
                        <span className={`arrowright ${selectedTab === index && 'active'}`}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg></span>
                    </div>
                    {selectedTab == index && selectedTab === 0 && <Website/>}
                    {selectedTab == index && selectedTab === 1 && <SocialAppsMobile defaultSocialAppInMobile = {defaultSocialAppInMobile} onlySocialAppsData = {onlySocialAppsData}/>}
                    {selectedTab == index && selectedTab === 2 && <div>
                        <div className="peer-checked/setup:block border-t-[1px] border-[#BFBFBF] mt-5 w-full">
					<div className="deployBoatInWebsiteRow flex flex-wrap">
						{/* <div className="deployeWebsiteLink md:border-r-[1px] border-[#ccc] w-full md:w-3/12 flex flex-row md:flex-col overflow-x-auto">
							<div class="depWebName flex items-center gap-3 px-5 py-3 border-b-[1px] border-[#ccc] group/depWeb bg-[#F2F5FF]  hover:bg-[#F2F5FF] cursor-pointer">
								<span class="deplWebName text-sm whitespace-nowrap">Deploy bot for Chat</span>
								<span class="deplWebArrow ml-auto visible group-hover/depWeb:visible w-2 h-2 flex-shrink-0">
									<svg stroke="currentColor" fill="#000" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
								</span>
							</div>
							
						</div> */}
                  		<div className="deployeWebsiteDetails w-full md:w-9/12">
							{/* <div class="breadcrumb px-5 pt-5 flex items-center flex-wrap">
								<span class="inline-block relative text-xs px-1 py-1 ">Self-service</span>
								<span class="inline-block relative text-xs px-1 py-1 "><svg stroke="currentColor" fill="#000" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></span>
								<span class="inline-block relative text-xs px-1 py-1 ">Deploy bot for Chat</span>
							</div> */}
							<div className="customeWebsite  px-5 py-5 aa" >
								<h3 className="text-base lg:text-xl text-black">Share the chatbot URL with your team or access it directly in your browser. You can also view our chatbot on this page directly</h3>
								<div className="bg-[#F3F5FE] p-4 flex items-center justify-between gap-3 my-4">
									<p className="text-sm md:text-base text-[#5873F7] font-medium whitespace-normal break-words break-all">{internalBotScript}</p>
			  						<button onClick={() => copyToClipboard(internalBotScript)} className="w-8 h-8 rounded-md bg-white p-1 text-[#5873F7] text-lg flex items-center justify-center hover:bg-[#5873F7] hover:text-white flex-shrink-0">
									  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path></svg>
									</button>
								</div>
								
								<button onClick={previewInternalBot} className="text-base text-[#5873F7] inline-flex items-center justify-center gap-2 mb-3 hover:underline">Go to preview <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M12.5 2.5H17.5V7.5" stroke="#5873F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.33398 11.6667L17.5007 2.5" stroke="#5873F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667" stroke="#5873F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
			  					<p className="text-sm text-[#6F6F6F] font-light">Note: Do not share the URL with anyone you do not wish to have access to your chatbot.</p>
								<div className="my-8 bottom-1 pb-8 border-b border-[#BFBFBF]">
									<h3 className="text-lg md:text-xl text-black font-medium mb-3">Permissions & Visibility</h3>
									<p className="md:text-base text-sm">Anyone with the link will be able to chat with the Bot and rate the chat threads that are created. To share the GPT bot directly just share the URL above and the trained bot will work as intended.</p>
								</div>
								<div className="my-4 md:my-8">
									<h3 className="text-lg md:text-xl text-black font-semibold mb-3">Want to Use the Bot in Private Mode?</h3>
									<p className="md:text-base text-sm">Follow the steps mentioned in the guide below to host this URL on Website Builder with Login Authentication to secure your GPT Bot.</p>
								</div>
								<p className="text-sm text-[#5873F7] font-medium">Add Login Authentication to your Bot using Appy Pie Website Builder?</p>
							</div>

			  				{/* {/ team start /} */}

							{/*<div className="p-5">
								<h3 className="text-base lg:text-xl text-black">Team</h3>
								<div  className="flex items-center justify-between gap-4 flex-wrap my-5">
									<button className="bg-[#2967F6] text-sm font-medium text-white rounded-lg p-3 hover:opacity-90 hover:shadow-lg">+ Invite Team Members</button>
									<input type="text" name=""  className="rounded-lg border px-4 py-3 text-sm text-[#ABABAB] w-96" placeholder="Search by name or email"/>
								</div>

			  					 <div className="relative rounded-xl  overflow-auto border border-slate-100">
									<table className=" w-full rounded-lg  table-auto border-collapse">
										<thead>
											<tr>
												<th className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-[#6F6F6F] text-sm text-left font-normal uppercase bg-[#F3F5FE]">Name</th>
												<th className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-[#6F6F6F] text-sm text-left font-normal uppercase bg-[#F3F5FE]">Email</th>
												<th className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-[#6F6F6F] text-sm text-left font-normal uppercase bg-[#F3F5FE]">Joined</th>
												<th className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-[#6F6F6F] text-sm text-left font-normal uppercase bg-[#F3F5FE]">Role</th>
												<th className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-[#6F6F6F] text-sm text-left font-normal uppercase bg-[#F3F5FE] w-[100px]">&nbsp;</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left"> Dharmendra</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black mailto:text-left">dharmendra@appypiellp.com</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left">Jun 18, 2024</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left">Owner</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left">
													
												</td>
											</tr>
											<tr>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left"> Dharmendra</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black mailto:text-left">dharmendra@appypiellp.com</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left">
													<span className="bg-[#DCEAFF] rounded px-3 py-1">Invited</span>
												</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left">Owner</td>
												<td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700 p-4 pl-8   text-sm text-black text-left">
													<button className="w-5 h-5">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M3 6H21" stroke="#E64C42" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 6V20C19 21 18 22 17 22H7C6 22 5 21 5 20V6" stroke="#E64C42" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 6V4C8 3 9 2 10 2H14C15 2 16 3 16 4V6" stroke="#E64C42" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 11V17" stroke="#E64C42" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 11V17" stroke="#E64C42" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div> 
							</div>*/}
			  				{/* {/ team end /} */}



						</div>
				  	</div>
				</div>
                        </div>}


                    
                    {/* {selectedTab === index ? index === 0 ? <Website/>  : <SocialAppsMobile defaultSocialAppInMobile = {defaultSocialAppInMobile} onlySocialAppsData = {onlySocialAppsData}/>   : null} */}
                </div>
            })}
        </div>}



    </div>
  )
}

export default SetupMobile