import { configureStore } from '@reduxjs/toolkit'
import userBotsReducers from '../features/userBots/userBotsSlice'
import botTemplateReducer from '../features/botTemplate/botTemplateSlice'
import botDesignReducer from '../features/botDesign/botDesignSlice'
import createBotReducer from '../features/createBot/createBotSlice'
import integrationReducer from '../features/integraion/integrationSlice'
import trainGptReducer from '../features/trainGpt/trainGptSlice'
import globalSaveReducer from '../features/globalSave/globalSaveSlice'
import agentReducer from '../features/agent/agentSlice'
import paymentReducer from '../features/payment/paymentSlice'
import popupReducer from '../features/popup/popupSlice'
import screenReducer from '../features/screen/screenSlice'
import ragReducer from '../features/rag/rag'

//creating redux store using redux toolkit
export const store = configureStore({
  reducer: {
    userBots : userBotsReducers,
    botTemplate : botTemplateReducer,
    botDesign : botDesignReducer,
    createBot : createBotReducer,
    integration : integrationReducer,
    trainGpt : trainGptReducer,
    globalSave : globalSaveReducer,
    agentData : agentReducer,
    payment : paymentReducer,
    popup : popupReducer,
    screen : screenReducer,
    rag:ragReducer
  },
})