import { setRag } from './rag';

export const getDataFromRagApiAsync = async () => {
  try {
    const web_url = "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net";
    console.log("----> RAG");
    const botId = localStorage.getItem("botId");
    const agentId = localStorage.getItem("agent_id");
    const userId = localStorage.getItem("tablealias");
    const apiUrl = `${web_url}/frontBotApi/api/chatbot/rag?companyId=${userId}&agentId=${agentId}&botId=${botId}&version=2.1`;

    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log("----> RAG data", data);
    if(data.success){
      return data.ragData;
    }
    else{
        return {isEnabled: false};

    }
    
  } catch (error) {
    console.error('Error fetching data from RAG API:', error);
    throw error;
  }
};