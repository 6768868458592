import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getInstalledIntegrationsAsync, getShorthandoffNamesAsync ,getPendingPublishAsync} from "./integrationThunk"

const initialState = {
    installedIntegrations: [],
    dgh: 'jnhadgsvhj',
    emailTemplates: {},
    createdTemplate: null,
    handof_short_txt: [],
    selectedHandoff: "",
    pendingPublish: false
}




//thunk
export const getInstalledIntegrations = createAsyncThunk('integration/getInstalledIntegration', getInstalledIntegrationsAsync)
export const getShorthandoffNames = createAsyncThunk('integration/handof_short_txt', getShorthandoffNamesAsync)
export const getPendingPublish = createAsyncThunk('integration/getPendingPublish', getPendingPublishAsync);



const integrationSlice = createSlice({
    name: 'integration',
    initialState,
    reducers: {
        updateSelectedHandoff: (state, action) => {
            state.selectedHandoff = action.payload
        },
        updateinstalledIntegrations: (state, action) => {
            state.installedIntegrations = action.payload
        },
        updateHandofShrtTxt: (state, action) => {
            state.handof_short_txt = action.payload
        },
        updateCreatedTemplate: (state, action) => {
            state.createdTemplate = action.payload
        },
        updatePendingPublish: (state, action) => {
            state.pendingPublish = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInstalledIntegrations.fulfilled, (state, action) => {
                state.installedIntegrations = action.payload.connect_installed
                state.emailTemplates = action.payload.emailTemplates
            })
            .addCase(getShorthandoffNames.fulfilled, (state, action) => {
                state.handof_short_txt = action.payload.shorthandoffNames;
            })
            .addCase(getPendingPublish.fulfilled, (state,action) =>{
                state.pendingPublish =  action.payload;
            })

    }
})






export const { updateSelectedHandoff, updateinstalledIntegrations, updateHandofShrtTxt, updatePendingPublish,updateCreatedTemplate } = integrationSlice.actions
export default integrationSlice.reducer;
