import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDataFromRagApiAsync } from "./ragThunk";

export const setRagData = createAsyncThunk('rag/setRagData', async (_, { dispatch }) => {
  const data = await getDataFromRagApiAsync();
  dispatch(setRag(data));
  console.log("RAG", data);
  return data;
});

const initialState = {
  rag: null,
};

const ragSlice = createSlice({
  name: "rag",
  initialState,
  reducers: {
    setRag: (state, action) => {
      state.rag = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRagData.fulfilled, (state, action) => {
      console.log("action.payload", action.payload);
      state.rag = action.payload;
    });
  },
});

export const { setRag } = ragSlice.actions;
export default ragSlice.reducer;