import { db } from "../../config/firebase";




export const getInstalledIntegrationsAsync = async () => {
  return db.collection('Connect_List')
    .doc(localStorage.getItem('tablealias'))
    .collection('chatbot')
    .doc(localStorage.getItem("botId"))
    .collection("all_integrations")
    .get().then(
      async coll => {
        // .get().then(coll=>{
        console.log(coll.docs.length, "collcollcollONSNAPSHOT")
        const connect_installed = []
        let emailTemplates = {}
        if (coll && coll.docs.length > 0) {

          console.log(coll, 'coll')
          console.log(coll.docs, 'coll.docs')
          coll.docs.forEach(doc => {
            console.log(doc.id, 'doc.id.installed')
            if (!connect_installed.includes(doc.id)) {
              connect_installed.push(doc.id)
              if (doc.id == 'appy_email') {
                console.log(doc.data())
                emailTemplates = doc.data()
              }
              if (doc.id === "gdrive") {
                connect_installed.push("googlesheets")
                connect_installed.push("google_sheets")
              }
            }
          });
          return { connect_installed, emailTemplates }
        }
        else {
          return { connect_installed, emailTemplates }
        }
      })
}


export const getShorthandoffNamesAsync = async () => {
  try {
    const tableAlias = localStorage.getItem('tablealias');
    const botId = localStorage.getItem('botId');

    if (!tableAlias || !botId) {
      throw new Error('Missing required localStorage data: tablealias or botId');
    }

    const docSnapshot = await db.collection('Connect_List')
      .doc(tableAlias)
      .collection('chatbot')
      .doc(botId)
      .get();

    if (!docSnapshot.exists) {
      throw new Error('Document not found');
    }

    const data = docSnapshot.data();
    const shorthandoffNames = data?.shorthandoffNames || [];

    return { success: true, shorthandoffNames };
  } catch (error) {
    console.error('Error getting data:', error);
    return { success: false, message: error.message };
  }
};




export const appendShorthandoffNames = async (newDataArray) => {
  try {
    const tableAlias = localStorage.getItem('tablealias');
    const botId = localStorage.getItem("botId");

    if (!tableAlias || !botId) { throw new Error('Missing required localStorage data: tablealias or botId'); }

    const docRef = db.collection('Connect_List')
      .doc(tableAlias)
      .collection('chatbot')
      .doc(botId);

    await docRef.set({ shorthandoffNames: newDataArray }, { merge: true });

  } catch (error) {
    console.error("Error updating data:", error);
    return { success: false, message: error.message };
  }
};


export const getPendingPublishAsync = async () => {
  return db.collection("user_" + localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .collection("chatbot")
    .doc(localStorage.getItem("botId"))
    .get()
    .then(async coll => {
      if (coll.exists && coll.data().pendingPublish !== undefined) { return coll.data().pendingPublish }
      else return false;
    })
}
